/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Row,
  Col,
  Drawer,
  Radio,
  Space,
  Modal,
  Skeleton,
  Pagination,
  Typography,
  Empty,
  Badge,
} from "antd";
import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";
import { addValue, updateContact } from "apiService/RESTApi";
import {
  getAllAttributeValues,
  getAttributeValues,
  getProduct,
  AddProduct,
  getContactProductRelations,
  getDataClassInstanceAttributeId,
  getDataClassInstanceAttributeValue,
  AddDataClassInstanceAttributeId,
  getContactbyID,
  deleteContactProductRelation,
} from "apiService/RESTApi";
import DeleteIcon from "../../assets/Images/DeleteIcon.svg";
import glowingBulbIcon from "../../assets/Images/glowingBulbIcon.svg";
import { useSelector } from "react-redux";

const { Search } = Input;

export default function ContactDetailPage() {
  const { roAdmin, groups } = useSelector((state) => state.loginReducer);

  const [form] = Form.useForm();
  const [productAttributeForm] = Form.useForm();
  const [productAddForm] = Form.useForm();
  const [addProductloading, setAddProductLoading] = useState(false);

  const [productDetails, setProductDetails] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productSearchValue, setProductSearchValue] = useState("");
  const location = useLocation();
  const { userToEdit } = location?.state;
  // eslint-disable-next-line
  const [contactDetailData, setContactDetailData] = useState([]);
  const [
    addContactProductAttributeDrawer,
    setAddContactProductAttributeDrawer,
  ] = useState(false);
  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  const [additionalContactAttributes, setAdditionalContactAttributes] =
    useState([]);
  const [contactAttributeLoading, setContactAttributeLoading] = useState(false);
  const [showAllContactAttributes, setShowAllContactAttributes] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactAttributeSaveLoader, setContactAttributeSaveLoader] =
    useState(false);
  const [showdeleteContactProductModal, setShowdeleteContactProductModal] =
    useState(false);
  const [selectedProductAttributes, setSelectedProductAttributes] = useState(
    []
  );
  const [
    filteredSelectedProductAttributes,
    setFilteredSelectedProductAttributes,
  ] = useState([]);
  const [contactsProducts, setContactsProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [updatedAttributes, setUpdatedAttributes] = useState([]);
  const [attributeSearchValue, setAttributeSearchValue] = useState("");
  const [productAttributeLoading, setProductAttributeLoading] = useState(false);
  const [saveProductAttributesLoading, setSaveProductAttributesLoading] =
    useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [contactProductDeleteLoading, setContactProductDeleteLoading] =
    useState(false);
  const [selectedContactProductToDelete, setSelectedContactProductToDelete] =
    useState({});
  const [updatedContactAttributes, setUpdatedContactAttributes] = useState({});

  // add contact product attribute
  const showAddContactDrawer = () => {
    setAddContactProductAttributeDrawer(true);
  };
  const ContactonClose = () => {
    setAddContactProductAttributeDrawer(false);
    productAddForm.resetFields();
  };

  const getAttributeApi = async () => {
    try {
      let val = await getAllAttributeValues(userToEdit?.customerId);
      let attributeValues = await getAttributeValues(userToEdit?.id);
      let dataArray = [];
      val?.response?.data?.forEach((el) => {
        dataArray.push({
          attributeId: el?.dataClassAttributeId,
          attributeName: el?.name,
        });
      });
      if (attributeValues && attributeValues?.response?.data?.length > 0) {
        // eslint-disable-next-line
        let newData = attributeValues?.response?.data?.map((el) => ({
          attributeValue: el?.value,
          attributeId:
            el?.dataClassInstanceAttributeDto?.dataClassAttributeDto
              ?.dataClassAttributeId,
          attributeName:
            el?.dataClassInstanceAttributeDto?.dataClassAttributeDto?.name,
        }));
        // eslint-disable-next-line
        dataArray.map((data) => {
          // eslint-disable-next-line
          newData.map((el) => {
            if (data.attributeId === el.attributeId) {
              data.attributeValue = el.attributeValue;
            }
          });
        });
        setContactDetailData(dataArray);
        setAdditionalContactAttributes(dataArray.slice(0, 5));
      }
      setContactAttributeLoading(false);
      return val;
    } catch (e) {
      console.log(e);
      message.error("Something Went Wrong");
      setContactAttributeLoading(false);
      return e;
    }
  };

  const getProductsApi = async () => {
    const fetchProducts = await getProduct(groups[0]);
    let productValue = fetchProducts?.response?.data;
    setProductDetails(productValue);
    setFilteredProducts(productValue);
    return fetchProducts;
  };

  useEffect(() => {
    function init() {
      setContactAttributeLoading(true);
      setProductLoading(true);
      setProductAttributeLoading(true);
      getAttributeApi();
      getProductsApi();
      getContactsProductsAndAttributes();
    }
    init();
    // eslint-disable-next-line
  }, []);

  const SaveProductAttributes = async () => {
    try {
      if (updatedAttributes?.length > 0) {
        setSaveProductAttributesLoading(true);
        var requestPending = updatedAttributes?.length;
        updatedAttributes.forEach((item) => {
          AddDataClassInstanceAttributeId(
            item?.dataClassInstanceAttributeId,
            item?.value
          ).then(() => {
            requestPending--;
            if (requestPending === 0) {
              setSaveProductAttributesLoading(false);
              message.success("Product attributes are saved successfully!");
            }
          });
        });
        setUpdatedAttributes([]);
      }
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };

  const onSubmit = async () => {
    try {
      setContactAttributeSaveLoader(true);

      for (let attribute in updatedContactAttributes) {
        const attributeId = parseInt(attribute);
        if (!isNaN(attributeId)) {
          let value = updatedContactAttributes[attributeId];
          if (value) {
            await addValue(attributeId, userToEdit?.id, value);
          }
        }
      }

      const contactsPrimaryData = {
        acctid: userToEdit?.id,
        firstName: form.getFieldValue("firstName"),
        lastName: form.getFieldValue("lastName"),
        primaryEmailAddress: form.getFieldValue("primaryEmailAddress"),
        primaryPhoneNumber: form.getFieldValue("primaryPhoneNumber"),
      };
      let response = await updateContact(contactsPrimaryData);
      setContactIdData(response);
      setContactAttributeSaveLoader(false);
      message.success("Contact attributes are saved successfully!");
      setUpdatedContactAttributes({});
    } catch (error) {
      console.log(error);
      message.error("Something went wrong!");
    }
  };

  const handleAddProduct = async (data) => {
    try {
      setAddProductLoading(true);
      await AddProduct(userToEdit?.id, data?.productId);
      const contact_products = await getContactsProducts();
      setContactsProducts(contact_products);
      productAddForm.resetFields();
      setAddContactProductAttributeDrawer(false);
      setAddProductLoading(false);
      if (!selectedProduct) {
        setSelectedProduct(contact_products?.[0]);
        setProductAttributeLoading(true);
        const productAttributes = await getProductAttributesInstanceDataId(
          contact_products?.[0]
        );
        setSelectedProductAttributes(productAttributes);
        setFilteredSelectedProductAttributes(productAttributes);
        setProductAttributeLoading(false);
      }
    } catch (error) {
      console.log(error);
      setAddProductLoading(false);
      setProductAttributeLoading(false);
    }
  };

  const [contactIdData, setContactIdData] = useState({});
  const fetchContactById = async () => {
    const res = await getContactbyID(userToEdit?.id);
    form.setFieldsValue(res);
  };
  useEffect(() => {
    fetchContactById();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showAllContactAttributes) {
      setAdditionalContactAttributes(contactDetailData);
    } else {
      setAdditionalContactAttributes(contactDetailData?.slice(0, 5));
    }
  }, [showAllContactAttributes]);

  const contactProductAttributePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <p>{`< Prev`}</p>;
    }
    if (type === "next") {
      return <p>{`Next >`}</p>;
    }
    return originalElement;
  };

  const getContactsProducts = async () => {
    try {
      const fetchExistingProduct = await getContactProductRelations(userToEdit?.customerId, userToEdit.id, null);
      const data = fetchExistingProduct?.response?.data ?? [];

      if (data.length === 0) {
        return [];
      }

      const productIds = new Set();
      const contactProducts = [];

      for (const contactProduct of data) {
        const { contactDto, productDto, contactProductId, dataClassInstanceDto } = contactProduct;

        if (contactDto?.contactId !== userToEdit?.id || productIds.has(productDto?.productId)) {
          continue;
        }

        contactProducts.push({
          Product: productDto?.name,
          cId: userToEdit?.customerId,
          pId: productDto?.productId,
          id: userToEdit?.id,
          contactProductId,
          dataClassInstanceDto
        });

        productIds.add(productDto?.productId);
      }

      return contactProducts;
    } catch (error) {
      console.error('Error fetching contact products:', error);
      return [];
    }
  };


  const getContactsProductsAndAttributes = async () => {
    try {
      const contact_products = await getContactsProducts();
      setContactsProducts(contact_products);
      setSelectedProduct(contact_products?.[0]);
      setProductLoading(false);
      const productAttributes = await getProductAttributesInstanceDataId(
        contact_products?.[0]
      );
      setSelectedProductAttributes(productAttributes);
      setFilteredSelectedProductAttributes(productAttributes);
      setProductAttributeLoading(false);
    } catch (error) {
      console.log(error);
      setProductLoading(false);
      setProductAttributeLoading(false);
    }
  };

  const getProductAttributesInstanceDataId = async (contact_product) => {
    let finalData = [];
    if (contact_product?.pId !== undefined) {
      let dataClassInstanceId = contact_product.dataClassInstanceDto?.dataClassInstanceId;
      let fetchAttributeId = await getDataClassInstanceAttributeId(
        dataClassInstanceId,
        contact_product?.pId
      );
      let temp = [];
      let dataClassInstanceAttributeIds = fetchAttributeId?.response?.data?.map(
        (item) =>
          temp.push({
            dataClassInstanceAttributeId: item?.dataClassInstanceAttributeId,
            dataClassAttributeId:
              item?.dataClassAttributeDto?.dataClassAttributeId,
          })
      );
      if (dataClassInstanceAttributeIds !== undefined) {
        await Promise.all(
          temp.map((item) =>
            getDataClassInstanceAttributeValue(
              item?.dataClassInstanceAttributeId
            )
          )
        ).then((res) => {
          let responseData = res.map((response, index) => {
            let dataObj = {};
            let productAttributeId = temp[index]["dataClassAttributeId"];
            if (productAttributeId) {
              dataObj = {
                productAttributeId,
                productAttributeName:
                  response?.response?.data?.[0]?.dataClassInstanceAttributeDto
                    ?.dataClassAttributeDto?.name,
                value:
                  response?.response?.data?.[0]?.value !== undefined
                    ? response?.response?.data?.[0]?.value
                    : null,
                dataClassInstanceAttributeId:
                  temp[index]["dataClassInstanceAttributeId"],
              };
            } else {
              dataObj = {
                productAttributeId,
                productAttributeName:
                  response?.response?.data?.[0]?.dataClassInstanceAttributeDto
                    ?.dataClassAttributeDto?.name,
                value:
                  response?.response?.data?.[0]?.value !== undefined
                    ? response?.response?.data?.[0]?.value
                    : null,
                dataClassInstanceAttributeId:
                  temp[index]["dataClassInstanceAttributeId"],
              };
            }
            return dataObj;
          });
          finalData = responseData;
        });
      }
      return finalData;
    }
  };

  useEffect(() => {
    setUpdatedAttributes([]);
  }, [selectedProduct]);

  const handleUpdatedContactAttributeList = (id, attributeValue) => {
    setUpdatedContactAttributes({
      ...updatedContactAttributes,
      [id]: attributeValue,
    });
  };

  return (
    <>
      <Row className="contact-detail-page-container" justify="center">
        <Col span={22} className="contact-detail-content-wrapper">
          <div className="contact-name-container">
            <UserOutlined className="contact-icon" />
            <h2 className="contact-name">
              {`${
                userToEdit?.firstName?.charAt(0)?.toUpperCase() +
                userToEdit?.firstName?.slice(1)
              }
              ${
                userToEdit?.lastName?.charAt(0)?.toUpperCase() +
                userToEdit?.lastName?.slice(1)
              }
              `}
            </h2>
          </div>
          <div className="contact-attributes-container">
            <div className="attributes-title-container">
              <p className="title">{`${
                userToEdit?.firstName?.charAt(0)?.toUpperCase() +
                userToEdit?.firstName?.slice(1)
              }’s attributes`}</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContect: "center",
                  gap: "8px",
                }}
              >
                {Object?.keys(updatedContactAttributes)?.length > 0 ? (
                  <Badge
                    dot
                    color="#124172"
                    style={{ height: "8px", width: "8px" }}
                  >
                    {" "}
                  </Badge>
                ) : null}
                <Button
                  className="standard-button secondary-orange-button"
                  onClick={() => {
                    if (Object?.keys(updatedContactAttributes)?.length > 0) {
                      onSubmit();
                    }
                  }}
                  loading={contactAttributeSaveLoader}
                  disabled={roAdmin}
                >
                  Save
                </Button>
              </div>
            </div>
            <Form
              form={form}
              layout="vertical"
              className="contact-attribute-form"
              initialValues={{ ...contactIdData }}
              requiredMark={false}
            >
              {contactAttributeLoading ? (
                <Row gutter={[20, 40]} style={{ marginBottom: "20px" }}>
                  {[...Array(9).keys()].map(() => {
                    return (
                      <Col span={24} xs={24} sm={24} md={12} lg={8}>
                        <Skeleton.Input
                          className="initial-skeleton-loader"
                          active={true}
                        />
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <Row gutter={[20, 0]}>
                  <Col span={24} xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="First name"
                      className="contact-attribute-form-item"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "This field can not be empty!",
                        },
                      ]}
                    >
                      <Input
                        className="standard-input"
                        disabled={roAdmin}
                        placeholder="First name"
                        onChange={(event) => {
                          handleUpdatedContactAttributeList(
                            "firstName",
                            event.target.value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Last name"
                      className="contact-attribute-form-item"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "This field can not be empty!",
                        },
                      ]}
                    >
                      <Input
                        className="standard-input"
                        disabled={roAdmin}
                        placeholder="Last name"
                        onChange={(event) => {
                          handleUpdatedContactAttributeList(
                            "lastName",
                            event.target.value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Phone Number"
                      className="contact-attribute-form-item"
                      name="primaryPhoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "This field can not be empty!",
                        },
                      ]}
                    >
                      <Input
                        className="standard-input"
                        disabled={roAdmin}
                        placeholder="Cell Number"
                        onChange={(event) => {
                          handleUpdatedContactAttributeList(
                            "primaryPhoneNumber",
                            event.target.value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} sm={24} md={12} lg={8}>
                    <Form.Item
                      label="Email Address"
                      className="contact-attribute-form-item"
                      name="primaryEmailAddress"
                    >
                      <Input
                        className="standard-input"
                        disabled={roAdmin}
                        placeholder="Email"
                        onChange={(event) => {
                          handleUpdatedContactAttributeList(
                            "primaryEmailAddress",
                            event.target.value
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {additionalContactAttributes &&
                    additionalContactAttributes?.map((items) => {
                      return (
                        <Col
                          span={24}
                          xs={24}
                          sm={24}
                          md={12}
                          lg={8}
                          key={items.attributeId}
                        >
                          <Form.Item
                            label={items.attributeName}
                            className="contact-attribute-form-item"
                            name={items.attributeId}
                            initialValue={items.attributeValue}
                          >
                            <Input
                              className="standard-input"
                              disabled={roAdmin}
                              placeholder={`Enter ${items?.attributeName}`}
                              onChange={(event) => {
                                handleUpdatedContactAttributeList(
                                  items.attributeId,
                                  event.target.value
                                );
                              }}
                            />
                          </Form.Item>
                        </Col>
                      );
                    })}
                </Row>
              )}
              {contactDetailData?.length > 5 ? (
                <p
                  className="view-more-attribute-button"
                  onClick={() =>
                    setShowAllContactAttributes(!showAllContactAttributes)
                  }
                >
                  {showAllContactAttributes ? "View less" : "View more"}
                </p>
              ) : null}
            </Form>
          </div>
          <div className="contact-product-attributes-container">
            <div className="attributes-title-container">
              <p className="title">{`${
                userToEdit?.firstName?.charAt(0)?.toUpperCase() +
                userToEdit?.firstName?.slice(1)
              }’s products`}</p>
              <div className="search-button-container">
                <Search
                  className="standard-searchbox"
                  allowClear
                  prefix={
                    <SearchOutlined className="standard-searchbox-prefix" />
                  }
                  value={attributeSearchValue}
                  placeholder="Search attribute"
                  disabled={
                    productAttributeLoading ||
                    !selectedProductAttributes?.length > 0
                  }
                  onChange={(event) => {
                    setCurrentPage(1);
                    setAttributeSearchValue(event.target?.value);
                    if (event.target?.value) {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes?.filter((item) => {
                          return item?.productAttributeName
                            .toLowerCase()
                            .includes(
                              event.target?.value?.trimStart()?.toLowerCase()
                            );
                        })
                      );
                    } else {
                      setFilteredSelectedProductAttributes(
                        selectedProductAttributes
                      );
                    }
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContect: "center",
                    gap: "8px",
                  }}
                >
                  {updatedAttributes?.length > 0 ? (
                    <Badge
                      dot
                      color="#124172"
                      style={{ height: "8px", width: "8px" }}
                    >
                      {" "}
                    </Badge>
                  ) : null}
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      if (updatedAttributes?.length > 0) {
                        SaveProductAttributes();
                      }
                    }}
                    loading={saveProductAttributesLoading}
                    disabled={roAdmin}
                  >
                    Save
                  </Button>
                </div>
                <Button
                  className="standard-button primary-orange-button"
                  disabled={roAdmin}
                  onClick={showAddContactDrawer}
                >
                  Add Product
                </Button>
              </div>
            </div>
            <Row gutter={[20, 20]}>
              <Col span={24} xs={24} sm={24} md={8} lg={7}>
                <div className="contacts-product-container">
                  {productLoading ? (
                    [...Array(5).keys()].map(() => {
                      return (
                        <div className="contacts-product-detail-container">
                          <div className="product-wrapper">
                            <Skeleton.Avatar active shape="circle" />
                            <Skeleton.Input className="product-name" active />
                          </div>
                        </div>
                      );
                    })
                  ) : contactsProducts?.length > 0 ? (
                    contactsProducts?.map((item) => {
                      return (
                        <div
                          id={item?.pId}
                          key={item?.pId}
                          className="contacts-product-detail-container"
                          onClick={async () => {
                            setAttributeSearchValue("");
                            setProductAttributeLoading(true);
                            setCurrentPage(1);
                            setSelectedProduct(item);
                            const productAttributes =
                              await getProductAttributesInstanceDataId(item);
                            setSelectedProductAttributes(productAttributes);
                            setFilteredSelectedProductAttributes(
                              productAttributes
                            );
                            setProductAttributeLoading(false);
                          }}
                        >
                          <div className="product-wrapper">
                            <Typography.Paragraph className="product-image">
                              {item?.Product?.slice(0, 1)?.toUpperCase()}
                            </Typography.Paragraph>
                            <Typography.Paragraph
                              className={`product-name ${
                                selectedProduct?.pId === item?.pId
                                  ? "highlight-product"
                                  : ""
                              }`}
                              ellipsis={{
                                rows: 1,
                              }}
                            >
                              {item?.Product}
                            </Typography.Paragraph>
                          </div>

                          {selectedProduct?.pId === item?.pId ? (
                            <div className="highlighted-bar"></div>
                          ) : null}
                        </div>
                      );
                    })
                  ) : (
                    <Empty
                      description="No products found!"
                      style={{
                        width: "calc(100% - 32px)",
                        minHeight: "350px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </div>
              </Col>
              <Col span={24} xs={24} sm={24} md={16} lg={17}>
                <Form
                  form={productAttributeForm}
                  layout="vertical"
                  className="contact-product-attribute-form"
                  requiredMark={false}
                >
                  {productAttributeLoading ? (
                    <Row gutter={[20, 40]} style={{ marginBottom: "20px" }}>
                      {[...Array(10).keys()].map(() => {
                        return (
                          <Col
                            span={24}
                            xs={24}
                            sm={24}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={8}
                          >
                            <Skeleton.Input
                              className="initial-skeleton-loader"
                              active={true}
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  ) : filteredSelectedProductAttributes?.length > 0 ? (
                    <Row gutter={[20, 0]}>
                      {filteredSelectedProductAttributes
                        ?.slice(10 * (currentPage - 1), 10 * currentPage)
                        ?.map((item) => {
                          return (
                            <Col
                              span={24}
                              xs={24}
                              sm={24}
                              md={12}
                              lg={12}
                              xl={12}
                              xxl={8}
                              key={item.attributeId}
                            >
                              <Form.Item
                                label={item?.productAttributeName}
                                className="contact-attribute-form-item"
                                name={item?.dataClassInstanceAttributeId}
                                initialValue={item?.value}
                              >
                                <Input
                                  className="standard-input"
                                  disabled={roAdmin}
                                  value={item?.value}
                                  placeholder={`Enter ${item?.productAttributeName}`}
                                  onChange={(event) => {
                                    const updatedAttribute =
                                      updatedAttributes?.find(
                                        (attributeObject) =>
                                          attributeObject?.dataClassInstanceAttributeId ===
                                          item?.dataClassInstanceAttributeId
                                      );
                                    if (updatedAttribute) {
                                      setUpdatedAttributes(
                                        updatedAttributes?.map(
                                          (attributeObject) => {
                                            if (
                                              attributeObject?.dataClassInstanceAttributeId ===
                                              item?.dataClassInstanceAttributeId
                                            ) {
                                              attributeObject.value =
                                                event.target.value;
                                              return attributeObject;
                                            }
                                            return attributeObject;
                                          }
                                        )
                                      );
                                    } else {
                                      item.value = event.target.value;
                                      setUpdatedAttributes([
                                        ...updatedAttributes,
                                        item,
                                      ]);
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          );
                        })}
                    </Row>
                  ) : (
                    <Empty
                      description="No data found!"
                      style={{
                        width: "100%",
                        minHeight: "400px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  )}
                </Form>
                {filteredSelectedProductAttributes?.length > 0 ? (
                  <div className="contact-product-attribute-pagination-container">
                    <p className="attribute-text">Showing 10 per page</p>
                    <Pagination
                      className="contact-product-attribute-pagination"
                      current={currentPage}
                      onChange={contactProductAttributePageChange}
                      total={filteredSelectedProductAttributes?.length}
                      itemRender={itemRender}
                    />
                  </div>
                ) : null}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {/* Add new contact product attribute drawer  */}
      <Drawer
        className="addProperty select-journey"
        width={430}
        title="Add new product"
        placement="right"
        onClose={ContactonClose}
        maskClosable={false}
        visible={addContactProductAttributeDrawer}
      >
        <Form
          form={productAddForm}
          layout="vertical"
          style={{ width: "100%" }}
          onFinish={handleAddProduct}
        >
          <h3 style={{ color: "#124173", marginBottom: 15 }}>Select product</h3>
          <div
            style={{ position: "relative", width: "100%", marginRight: "10px" }}
          >
            <Search
              className="standard-searchbox"
              allowClear
              prefix={<SearchOutlined className="standard-searchbox-prefix" />}
              placeholder="Search Product"
              style={{ width: "100%" }}
              value={productSearchValue}
              onChange={(event) => {
                setProductSearchValue(event.target.value);
                if (event.target.value) {
                  setFilteredProducts(
                    productDetails?.filter((item) => {
                      return item?.name
                        .toLowerCase()
                        .includes(event.target?.value?.toLowerCase());
                    })
                  );
                } else {
                  setFilteredProducts(productDetails);
                }
              }}
            />
          </div>
          <Form.Item
            name="productId"
            rules={[
              {
                required: true,
                message: "Please select the product!",
              },
            ]}
          >
            <Radio.Group
              style={{
                width: "100%",
                marginTop: 20,
                overflow: "scroll",
                maxHeight: "400px",
              }}
            >
              <Space direction="vertical">
                {filteredProducts?.map((value) => {
                  return (
                    <Radio key={value?.productId} value={value?.productId}>
                      {value?.name}
                    </Radio>
                  );
                })}
              </Space>
            </Radio.Group>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              left: 0,
              width: "auto",
              borderTop: "1px solid #e9e9e9",
              fontSize: "18px",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "right",
              display: "flex",
            }}
          >
            <Button
              className="standard-button tertiary-button"
              style={{ marginRight: 8, width: "48%" }}
              size="middle"
              onClick={ContactonClose}
              disabled={addProductloading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="standard-button primary-orange-button"
              size="middle"
              loading={addProductloading}
            >
              Add
            </Button>
          </div>
        </Form>
      </Drawer>

      {/* delete modal  */}
      <Modal
        visible={isInviteUserModelOpen}
        className="invite-modal"
        footer={null}
        width={370}
        centered
        closeIcon={<></>}
        onCancel={() => {
          setisInviteUserModelOpen(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: "10px 10px 50px",
          }}
        >
          <img src={DeleteIcon} height="40" alt="" />
          <h3 style={{ color: "#252525", margin: "10px 0 20px" }}>
            Are you sure you want to delete this Attribute ?
          </h3>

          <p style={{ color: "#797979" }}>
            You will loose all the data relevant to this attribute in Touchpoint
            & Contact manager
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              className="standard-button tertiary-button"
              style={{
                marginRight: 8,
                width: "48%",
                border: "1px solid #252525",
                color: "black",
              }}
              size="middle"
              onClick={() => {
                setisInviteUserModelOpen(false);
              }}
            >
              No
            </Button>
            <Button
              type="primary"
              style={{ width: "48%" }}
              htmlType="submit"
              className="standard-button primary-orange-button"
              size="middle"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      {/* delete contacts product modal */}
      <Modal
        centered
        visible={showdeleteContactProductModal}
        closable={false}
        footer={null}
        className="delete-contacts-product-modal"
      >
        <div className="delete-content-container">
          <img src={glowingBulbIcon} alt="" />
          <p className="contacts-product-delete-modal-dark-text">
            {`Are you sure that you want to remove the product ${selectedContactProductToDelete?.Product}?`}
          </p>
          <p className="contacts-product-delete-modal-light-text">
            Once deleted, all product attributes linked with this contact will
            also be removed!
          </p>
          <div className="delete-contacts-product-modal-button-wrapper">
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={async () => {
                try {
                  setContactProductDeleteLoading(true);
                  await deleteContactProductRelation(
                    selectedContactProductToDelete.contactProductId
                  );
                  const contact_products = await getContactsProducts();
                  setContactsProducts(contact_products);
                  setContactProductDeleteLoading(false);
                  setShowdeleteContactProductModal(false);
                  if (
                    selectedContactProductToDelete?.pId === selectedProduct?.pId
                  ) {
                    setAttributeSearchValue("");
                    setSelectedProduct(contact_products?.[0]);
                    setProductAttributeLoading(true);
                    const productAttributes =
                      await getProductAttributesInstanceDataId(contact_products?.[0]);
                    setSelectedProductAttributes(productAttributes);
                    setFilteredSelectedProductAttributes(productAttributes);
                    setUpdatedAttributes([]);
                    setProductAttributeLoading(false);
                  }
                } catch (error) {
                  console.log(error);
                  setContactProductDeleteLoading(false);
                  setShowdeleteContactProductModal(false);
                }

                setSelectedContactProductToDelete({});
              }}
              loading={contactProductDeleteLoading}
            >
              Yes, I understand
            </Button>
            <Button
              className="standard-button secondary-orange-button"
              onClick={() => setShowdeleteContactProductModal(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
