/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  ConfigProvider,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Dropdown,
  Drawer,
  Menu,
  Radio,
  Form,
  Space,
  Checkbox,
  Alert,
  Skeleton,
  Typography,
} from "antd";
import { addAttribute, getContactbyID } from "apiService/RESTApi";
import {
  SearchOutlined,
  DeleteOutlined,
  Loading3QuartersOutlined,
  CalculatorFilled,
} from "@ant-design/icons";
import "./contact.css";
import exportDataSVG from "../../assets/Images/exportDataSVG.svg";
import DeleteIcon from "../../assets/Images/DeleteIcon.svg";
import contactManagerSVG from "../../assets/Images/contactManagerSVG.svg";
import ContactImportedIcon from "../../assets/Images/ContactImportedIcon.svg";
import CSVIcon from "../../assets/Images/CSVIcon.svg";
import successIcon from "../../assets/Images/successIcon.svg";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import uploadFileToS3 from "../../utility/uploadFileToS3";
import {
  deleteContact,
  contactSearch,
  getCustomerId,
  getProduct,
} from "apiService/RESTApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Search } = Input;
const { Dragger } = Upload;
let debounceValue = "";

const Contacts = () => {
  const [loading, setLoading] = useState(false);
  const [isInviteUserModelOpen, setisInviteUserModelOpen] = useState(false);
  const [csvModel, setCsvModel] = useState(false);
  const [csvImportedModel, setCsvImportedModel] = useState(false);
  const [linkProductModel, setLinkProductModel] = useState(false);
  const [linkRadiioModel, setLinkRadioModel] = useState(false);
  const [linkProductSuccessModel, setLinkProductMSuccessodel] = useState(false);
  const [termsConditionModel, setTermsConditionModel] = useState(false);
  const [tabledata, settabledata] = useState([]);
  const { groups, roAdmin } = useSelector((state) => state.loginReducer);
  const [contacttoDelete, setcontacttoDelete] = useState({});
  const [tableLoading, settableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTablePageSize, setCurrentTablePageSize] = useState(10);
  const [addProductAttributeDrawer, setAddProductAttributeDrawer] = useState(false);
  const [customerId, setCustomerId] = useState();
  const [allContactData, setAllContactData] = useState([]);
  const [error, setError] = useState("");
  const [firstData, setFirstData] = useState("");
  const [selectModel, setSelectModel] = useState([]);
  const [form] = Form.useForm();
  const [csvForm] = Form.useForm();
  const [productForm] = Form.useForm();
  const history = useHistory();
  const [productDetails, setProductDetails] = useState([]);
  const [productLoader, setProductLoader] = useState(false);
  const [productId, setProductId] = useState([]);
  const [contactSearchLoader, setContactSearchLoader] = useState(false);
  const [contactSearchQuery, setContactSearchQuery] = useState("");
  const [isSearchDisable, setIsSearchDisable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [shouldShowUploadList, setShouldShowUpList] = useState(false);
  const [fileName, setFileName] = useState('');
  const [products, setProducts] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [paginationProperties, setPaginationProperties] = useState({
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    position: ["bottomCenter"]
  });

  function handleProductChange(e, value) {
    let temp = [...productId];
    let tempProducts = [...products];
    if (e === true) {
      temp.push(value.productId);
      tempProducts.push(value.name);
    } else {
      let indexData = temp.indexOf(value.productId);
      temp.splice(indexData, 1);
      tempProducts.splice(indexData, 1);
    }
    setProducts(tempProducts);
    setProductId(temp);
  }

  const [skeletonLoader, setskeletonLoader] = useState(false);
  useEffect(async () => {
    setskeletonLoader(true);
    await getContactsData("", paginationProperties);
    setskeletonLoader(false);
  }, []);

  const handleContactTablePageChange = (value) => {
    let newPaginationProperties = {};
    if (currentTablePageSize != value.pageSize) {
      newPaginationProperties = {
        current: 1,
        pageSize: value.pageSize,
        total: 0,
        showSizeChanger: value.showSizeChanger,
        position: value.position
      };
    }
    else {
      newPaginationProperties = {
        current: value.current,
        pageSize: value.pageSize,
        total: value.total,
        showSizeChanger: value.showSizeChanger,
        position: value.position
      };
    }

    setPaginationProperties(newPaginationProperties);
    getContactsData(contactSearchQuery, newPaginationProperties);
  };

  //  get Contacts
  const getContactsData = async (searchQuery, paginationState) => {
    setLoading(true);
    await getCustomerId(groups[0]).then(async (res) => {
      setCustomerId(res.response.data[0].customerId);
      const customerArn = res.response.data[0].customerArn;
      const searchResults = await contactSearch(
        customerArn,
        searchQuery,
        paginationState.current,
        paginationState.pageSize
      );
      const promiseArray = searchResults?.data?.map((contact) => {
        return getContactbyID(contact?.contactId);
      });
      Promise.all(promiseArray).then((values) => {
        const contacts = values?.map((item) => ({
          customerId: item?.customerId,
          id: item?.contactId,
          firstName: item?.firstName,
          lastName: item?.lastName,
          primaryPhoneNumber: item?.primaryPhoneNumber
            ? item?.primaryPhoneNumber
            : "-",
          primaryEmailAddress: item?.primaryEmailAddress
            ? item?.primaryEmailAddress
            : "-",
        }));

        setPaginationProperties({
              current: searchResults.page.number + 1,
              pageSize: searchResults.page.size,
              total: searchResults.page.totalElements,
              showSizeChanger: true,
              position: ["bottomCenter"]});

          setCurrentTablePageSize(searchResults.page.size);
          settabledata(contacts);
          settableLoading(false);
          return contacts;
        });
    })
    .catch((error) => {
      console.log("Error :", error);
      settableLoading(false);
      return error;
    });
    setLoading(false);
  };

  const onSearch = (value) => {
    setLoading(true);
    setContactSearchQuery("");
    if (value) {
      value = value.trim();
    }
    if (value.length == 0 || value.length > 2 ) {
      setContactSearchQuery(value);
      clearTimeout(debounceValue);
      debounceValue = setTimeout(async () => {
        setContactSearchLoader(true);
        setIsSearchDisable(true);

        const newPaginationProperties = {
          current: 1,
          pageSize: paginationProperties.pageSize,
          total: 0,
          showSizeChanger: paginationProperties.showSizeChanger,
          position: paginationProperties.position
        };
       
        setPaginationProperties(newPaginationProperties);
        getContactsData(value, newPaginationProperties);

        setContactSearchLoader(false);
        setIsSearchDisable(false);
      }, 1000);
    } else {
      clearTimeout(debounceValue);
      settabledata(allContactData);
    }

    setLoading(false);
  };

  const handleAttribute = async () => {
    setLoading(true);
    try {
      let resolveData = {
        customerId: customerId,
        ...form.getFieldsValue(),
      };
      let res = await addAttribute(resolveData);
      if (res) {
        message.success("Attribute Added successfully!");
        setLoading(false);
      } else {
        message.error("Something went wrong");
      }
    } catch (e) {
      console.log(e);
      message.error("Something went wrong!");
    }
    setAddProductAttributeDrawer(false);
    form.resetFields();
    setLoading(false);
  };

  

  // to delete the contact
  const handleDeleteContact = async (record) => {
    settableLoading(true);
    await deleteContact(contacttoDelete?.id)
      .then((res) => {
        setcontacttoDelete({});
        const updatedTableData = tabledata?.filter(
          (contact) => contact?.id !== contacttoDelete?.id
        );
        const updatedAllContactData = allContactData?.filter(
          (contact) => contact?.id !== contacttoDelete?.id
        );
        setAllContactData(updatedAllContactData);
        settabledata(updatedTableData);
        settableLoading(false);
        setisInviteUserModelOpen(false);
      })
      .catch((err) => {
        console.error(err);
        settableLoading(false);
      });
  };

  const AddonClose = () => {
    setAddProductAttributeDrawer(false);
  };

  const getProductsApi = async () => {
    setLoading(true);
    setProductLoader(true);
    await getProduct(groups[0])
      .then((res) => {
        let productValue = res?.response?.data;
        setProductDetails(productValue);
        setLoading(false);
        setProductLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setProductLoader(false);
      });
  };

  useEffect(() => {
    if (customerId) {
      getProductsApi();
    }
  }, [customerId]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "20%",
    },
    // mvp commented code
    {
      title: "Last Name",
      dataIndex: "lastName",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "20%",
    },
    {
      title: "Phone Number",
      dataIndex: "primaryPhoneNumber",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "26%",
    },
    {
      title: "Email Address",
      dataIndex: "primaryEmailAddress",
      render: (text, record) => (
        <Link
          to={{
            pathname: "/contacts/contact-detail",
            state: {
              userToEdit: record,
            },
          }}
        >
          {text}
        </Link>
      ),
      width: "26%",
    },

    {
      title: "",
      dataIndex: "delete",
      render: (data, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                disabled={roAdmin}
                onClick={() => {
                  setisInviteUserModelOpen(true);
                  setcontacttoDelete(record);
                }}
              >
                <Typography.Text>Delete Contact</Typography.Text>
              </Menu.Item>
            </Menu>
          }
        >
          <Button className="ant-dropdown-link button" type="text">
            <DeleteOutlined />
          </Button>
        </Dropdown>
      ),
      align: "center",
      width: "8%",
    },
  ];

  const customizeRenderEmpty = () => (
    <div className="noDataScreen">
      <h1>No contact found</h1>
      <img
        src={exportDataSVG}
        height="90"
        alt=""
        style={{ marginBottom: "20px" }}
      />
      <p>
        Get started with the contacts manager by first
        <br /> importing the product CSV data file
      </p>
      <Button
        className="standard-button tertiary-button"
        type="secondary"
        onClick={handleCsv}
      >
        Import CSV data file
      </Button>
    </div>
  );

  const removeFile = () => {
    let deletebtn = document.getElementById("delete-file-icon");
    deletebtn && deletebtn.click();
  };
  useEffect(() => {
    removeFile();
  }, [csvModel]);

  const [CSVMessage, setCSVMessage] = useState("");
  const props = {
    name: "file",
    multiple: false,
    fileList: fileList,
    beforeUpload: (file) => {
      fileList.push(file)
      setFileName(file.name); 
      setShowConfirmation(true);
      return false;
    },
    showUploadList: shouldShowUploadList
    ? {
        showRemoveIcon: true,
        removeIcon: <DeleteOutlined id="delete-file-icon" />,
      }
    : false,
  };

  const handleClear = () => {
    setShowConfirmation(false)
    setShouldShowUpList(false);
    setFileName("")
    setFileList([]);
    setProducts([]);
    setProductId([]);
    console.log("clear upload")
  };

  const handleConfirm = () => {
    setShouldShowUpList(true);
    setShowConfirmation(false)
    uploadAndProcessCSV({
      onSuccess: (message) => {
        handleClear();
        console.log(message);
      },
      onError: (error) => {
        console.error(error); 
        handleClear();
      },
      file: fileList.length > 0 ? fileList[0] : []
    });
  };

  const uploadAndProcessCSV = async ({ onSuccess, onError, file }) => {

    setCSVMessage("Uploading");
    const res = await uploadFileToS3(file, productId, customerId);
    try {
      setCSVMessage("Processing");
      let productIdData = productId.toString();
      const queryParams = new URLSearchParams({
        filename: `public/${res.key}`,
        productId: productIdData,
        accountId: groups[0],
        customerId: customerId
    });
      await API.get("blustreamREST", `/processCSV?${queryParams}`)
        .then((res) => {
          setCsvModel(false);
          setCsvImportedModel(true);
          getContactsData(currentPage, 2000, true);
          setCurrentPage(currentPage);
          onSuccess(res);
          setCSVMessage("");
        })
        .catch((err) => {
          console.log(`Processing CSV timed out!`)
          err.message = "Processing CSV timed out!";
          onError(err);
          setCSVMessage("");
        });
    } catch (error) {
      error.message = "Processing CSV timed out!";
      console.log("Error:", error.response.data);
      message.error(error?.response?.data);
      onError(error);
      setCSVMessage("");
    }
  };

  const handleClick = () => {
    csvForm.getFieldsValue();
    if (selectModel === "csv") {
      if (productDetails?.length > 0) {
        setLinkRadioModel(true);
      } else {
        setCsvModel(true);
      }
      setTermsConditionModel(false);
    } else {
      history.push({
        pathname: "/contacts/create-contact",
        state: { customerId: customerId },
      });
    }
  };

  const handleCsv = async () => {
    setSelectModel("csv");
    if (firstData === null) {
      setTermsConditionModel(true);
    } else {
      if (productDetails?.length > 0) {
        setLinkRadioModel(true);
      } else {
        setCsvModel(true);
      }
    }
  };

  const addContactLink = () => {
    setSelectModel("contact-add");
    if (tabledata?.length === 0) {
      setTermsConditionModel(true);
    } else {
      history.push({
        pathname: "/contacts/create-contact",
        state: { customerId: customerId },
      });
    }
  };
  
  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <Row>
            <Col span={16} xl={16} lg={24} md={24} sm={24} xs={24}>
              <div
                style={{
                  paddingLeft: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <h2
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "700",
                    fontSize: "22px",
                    lineHeight: "26px",
                    color: "#464646",
                    marginBottom: "15px",
                  }}
                >
                  Welcome to Blustream{" "}
                  <span style={{ color: "#F5786D" }}>Contacts</span> Manager!
                </h2>
                <p
                  style={{
                    fontFamily: "Hellix",
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "26px",
                    color: "#464646",
                    maxWidth: "497px",
                    marginBottom: "0px",
                  }}
                >
                  Manage all your contacts details from this dashboard. Add or
                  update new properties as per your requirements easily!
                </p>
              </div>
            </Col>
            <Col
              span={8}
              xl={8}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <img
                src={contactManagerSVG}
                style={{ maxHeight: "151px", paddingRight: "20px" }}
                alt=""
              />
            </Col>
          </Row>
        </div>
        <Drawer
          width={430}
          title="Add new product attribute"
          placement="right"
          onClose={AddonClose}
          loading={loading}
          maskClosable={false}
          visible={addProductAttributeDrawer}
        >
          <Form
            onFinish={handleAttribute}
            form={form}
            loading={loading}
            layout="vertical"
            className="product-attribute-form"
          >
            <Form.Item
              label="Attribute Title"
              name="AttributeTitle"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Input
                className="standard-input"
                placeholder="Enter attribute title"
              />
            </Form.Item>

            <Form.Item
              label="Attribute Type"
              style={{ width: "100%" }}
              name="AttributeType"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={1}>Long</Radio>
                  <Radio value={2}>Date Selection</Radio>
                  <Radio value={3}>String</Radio>
                  <Radio value={4}>Boolean</Radio>
                  <Radio value={5}>Integer</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                left: 0,
                width: "auto",
                borderTop: "1px solid #e9e9e9",
                fontSize: "18px",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "right",
                display: "flex",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{ marginRight: 8, width: "48%" }}
                size="middle"
                onClick={AddonClose}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={loading}
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
              >
                Add
              </Button>
            </div>
          </Form>
        </Drawer>

        <div className="contactBox">
          <div className="dataContent">
            <div className="tableHeader tableFlexBox">
              <h3 className="all-contact-heading">All Contacts</h3>

              <div className="flexBox contactFlexBox">
                {!skeletonLoader && tableLoading && (
                  <Loading3QuartersOutlined spin style={{ marginRight: 10 }} />
                )}
                {skeletonLoader || productLoader ? (
                  <Skeleton.Button active style={{ width: 170 }} />
                ) : (
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      marginRight: "10px",
                    }}
                  >
                    <Search
                      className="standard-searchbox"
                      prefix={
                        <SearchOutlined className="standard-searchbox-prefix" />
                      }
                      placeholder="Search contacts"
                      onChange={(e) => { onSearch(e.target.value); }}
                      style={{ width: "100%" }}
                      allowClear
                    />
                  </div>
                )}
                <div className="flexbox-container">
                  {skeletonLoader || productLoader ? (
                    <Skeleton.Button
                      active
                      style={{ width: 70, marginLeft: 10 }}
                    />
                  ) : (
                    <Button
                      className="standard-button primary-orange-button"
                      onClick={addContactLink}
                      disabled={roAdmin}
                      type="secondary"
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Add Contact
                    </Button>
                  )}
                  {skeletonLoader || productLoader ? (
                    <Skeleton.Button
                      active
                      style={{ width: 70, marginLeft: 10 }}
                    />
                  ) : (
                    <Button
                      className="standard-button tertiary-button"
                      disabled={roAdmin}
                      onClick={handleCsv}
                      type="secondary"
                    >
                      {" "}
                      Import CSV{" "}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <ConfigProvider renderEmpty={tabledata && customizeRenderEmpty}>
              {skeletonLoader || contactSearchLoader ? (
                <Skeleton active paragraph={{ rows: 5 }} />
              ) : (
                <Table
                  className="contacts-table"
                  columns={columns}
                  dataSource={tabledata}
                  onChange={handleContactTablePageChange}
                  scroll={{ y: 325 }}
                  pagination={paginationProperties}
                  style={{ height: "calc(100vh - 365px)" }}
                />
              )}
            </ConfigProvider>
          </div>
        </div>

        <Modal
          visible={isInviteUserModelOpen}
          className="invite-modal"
          footer={null}
          width={350}
          centered
          closeIcon={<></>}
          maskClosable={false}
          onCancel={() => {
            setisInviteUserModelOpen(false);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "10px 10px 50px",
            }}
          >
            <img src={DeleteIcon} height="40" alt="" />
            <h3 style={{ color: "#252525", margin: "10px 0 20px" }}>
              Are you sure you want to
              <br /> delete this contact ?
            </h3>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                className="standard-button tertiary-button"
                style={{
                  marginRight: 8,
                  width: "48%",
                }}
                size="middle"
                disabled={tableLoading}
                onClick={() => {
                  setisInviteUserModelOpen(false);
                }}
              >
                No
              </Button>
              <Button
                loading={tableLoading}
                type="primary"
                style={{ width: "48%" }}
                htmlType="submit"
                className="standard-button primary-orange-button"
                size="middle"
                onClick={handleDeleteContact}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      {/* import csv modal  */}
      <Modal
        open={csvModel}
        className="csv-modal"
        footer={null}
        width={600}
        centered
        closeIcon={<></>}
        onCancel={() => {
          handleClear();
          setCsvModel(false);
        }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={9} className="sampleCsv" style={{ background: "#E8F3FF" }}>
            <img src={CSVIcon} alt="" />
            <p>
              CSV should be in specific format. Please check the sample CSV
              format
            </p>
            <a
              className="standard-button primary-orange-button"
              href="https://blustreamfrontend-static-images.s3.amazonaws.com/example-import.csv"
              target="_blank"
              rel="noreferrer"
            >
              Download sample
            </a>
          </Col>

          <Col span={15} className="drag-file">
            <Dragger {...props}>
              <p className="ant-upload-text">Drag and drop CSV file here</p>
            </Dragger>
            <div style={{ paddingTop: 10 }}>{CSVMessage}</div>
          </Col>
        </Row>
      </Modal>

       {/* upload confirmation modal */}
       <Modal
        visible={showConfirmation}
        className="upload-confirmation-modal"
        footer={null}
        width={350}
        centered
        onCancel={handleClear}
      >

        <div className="upload-confirmation-div" >
        <img src={ContactImportedIcon} height="40px" alt="Upload" />
        {
          products.length > 0 ? (
            <p className="confirm-message">
              You are about to upload "{fileName}" and link the following products. This may start contacts on live journeys. Do you want to proceed?
            </p>
          ) : (
            <p className="confirm-message">
              You are about to upload "{fileName}" without any products associated to these contacts. Do you want to proceed?
            </p>
          )
        }

        </div>
        {
              products.length > 0 && (
                <div className="scrollable-list-container">
                  <ul className="scrollable-list">
                    {products.map((product, index) => (
                      <li key={index}>
                        {product}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }

          <div className="confirmation-call-to-action">
          <Button className="ant-btn ant-btn-secondary standard-button tertiary-button upload-cancel-button" onClick={handleClear}>
            Cancel
          </Button>
          <Button className="ant-btn ant-btn-secondary standard-button primary-orange-button upload-confirm-button" onClick={handleConfirm}>
            Proceed
          </Button>
        </div>
      </Modal>

      {/* Importing csv success modal */}
      <Modal
        visible={csvImportedModel}
        className="csv-modal"
        footer={null}
        width={350}
        centered
        onCancel={() => {
          setCsvImportedModel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          <img src={ContactImportedIcon} height="40px" alt="Contacts" />
          <h3 style={{ color: "#252525", fontWeight: 600, marginTop: 10 }}>
            Your contacts have been uploaded. Please allow some time for them to
            appear. Check your email for a report for any errors or duplicates.
          </h3>
        </div>
      </Modal>
      {/*terms & condition modal*/}
      <Modal
        visible={termsConditionModel}
        className="csv-modal"
        footer={null}
        width={400}
        centered
        closeIcon={<></>}
        onCancel={() => {
          setTermsConditionModel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          {error && (
            <Alert
              style={{ marginBottom: "10px" }}
              type="error"
              showIcon={false}
              message={error}
              banner
            />
          )}
          <Form
            requiredMark={false}
            form={csvForm}
            name="normal_login"
            onSubmit={handleClick}
          >
            <Form.Item>
              <p>
                Asking your contacts to opt-in to receive SMS messages creates a
                great and trusted experience. Not only is it polite, it's
                legally required in many regions. Please make sure you stay
                compliant with any applicable SMS communication laws.
              </p>
            </Form.Item>
            <Form.Item>
              <Button
                className="standard-button primary-orange-button"
                onClick={handleClick}
                htmlType="submit"
                loading={loading}
                type="primary"
              >
                I understand
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/* linking product modal  */}
      <Modal
        visible={linkProductModel}
        className="addProperty select-journey"
        footer={null}
        width={430}
        centered
        onCancel={() => {
          setLinkProductModel(false);
        }}
      >
        <Form layout="vertical" style={{ width: "100%" }}>
          <h3 style={{ color: "#124173", marginBottom: 15 }}>Select product</h3>

          <Radio.Group style={{ width: "100%" }}>
            <Space direction="vertical">
              <Radio value={1}>Golden Retriever</Radio>
              <Radio value={2}>Labrador</Radio>
              <Radio value={3}>Australian Shepherd</Radio>
              <Radio value={4}>German Shepherd</Radio>
              <Radio value={5}>Chihauhua</Radio>
              <Radio value={6}>Dachsund</Radio>
              <Radio value={7}>Poodle</Radio>
              <Radio value={8}>Labradoodle</Radio>
              <Radio value={9}>Beagle</Radio>
              <Radio value={10}>Akita</Radio>
            </Space>
          </Radio.Group>

          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              setLinkProductModel(false);
              setLinkProductMSuccessodel(true);
            }}
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Link Now
          </Button>
        </Form>
      </Modal>

      {/* success modal  */}
      <Modal
        visible={linkProductSuccessModel}
        className="addProperty select-journey"
        footer={null}
        width={350}
        centered
        onCancel={() => {
          setLinkProductMSuccessodel(false);
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
            textAlign: "center",
          }}
        >
          <img src={successIcon} height="50px" alt="Success" />
          <h3 style={{ color: "#252525", fontWeight: 600, marginTop: 20 }}>
            Contacts linked successfully with “Golden Retriever”
          </h3>
        </div>
      </Modal>
      <Modal
        open={linkRadiioModel}
        className="addProperty select-journey"
        footer={null}
        width={430}
        centered
        onCancel={() => {
          productForm.resetFields();
          handleClear();
          setLinkRadioModel(false);
        }}
      >
        <Form form={productForm} layout="vertical" style={{ width: "100%" }}>
          <h3 style={{ color: "#124173", marginBottom: 15 }}>
            Which product would you like contacts to link with?
          </h3>
          <Form.Item name="checked" valuePropName="checked">
            {productDetails?.map((value, key) => {
              return (
                <Checkbox
                  key={key}
                  onChange={(e) => handleProductChange(e.target.checked, value)}
                >
                  {value?.name}
                </Checkbox>
              );
            })}
          </Form.Item>

          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              productForm.resetFields();
              setLinkRadioModel(false);
              setCsvModel(true);
            }}
            htmlType="submit"
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Continue
          </Button>
          <Button
            className="standard-button primary-orange-button"
            onClick={() => {
              productForm.resetFields();
              setLinkRadioModel(false);
              setCsvModel(true);
              setProductId([]);
              setProducts([]);
            }}
            style={{
              marginTop: 10,
              width: "100%",
              marginBottom: 10,
            }}
          >
            Skip
          </Button>
        </Form>
      </Modal>

      {/* mvp commented code  */}
      {/* <AttributeDrawer /> */}
    </>
  );
};

export default Contacts;
